import styled from "styled-components"

import { colorScale, colorsV2 } from "src/ui/colors"
import { fontWeight } from "src/ui/fontWeight"
import { spacing } from "src/ui/spacing"

type MBadgeProps = {
  children: string
  size?: "small" // add support for other sizes if necessary
  color?: "info" | "error" | "neutral" | "good" | "warning" | "promotional"
  borderRadius?: "round" | "square"
  border?: boolean
}

export function MBadge({
  children: text,
  color = "info",
  borderRadius = "round",
  border = false,
}: MBadgeProps) {
  return (
    <MBadgeBox
      $color={color}
      $size={"small"} // hardcoded to small; add support for other sizes if necessary
      $borderRadius={borderRadius}
      $border={border}
    >
      {text}
    </MBadgeBox>
  )
}

/* A remapping of MBadgeProps to include the $ prefix required for styled-components. */
type MBadgeBoxProps = {
  [Prop in keyof Omit<MBadgeProps, "children"> as `$${Prop}`]: MBadgeProps[Prop]
}

const MBadgeBox = styled.div<MBadgeBoxProps>`
  display: inline-block;
  text-align: center;
  word-break: normal;
  font-weight: ${fontWeight.medium};

  ${({ $size }) => {
    switch ($size) {
      case "small":
        return `
          padding: ${spacing.XS3} ${spacing.XS};
          font-size: 0.875rem;
        `
    }
  }}

  ${({ $color, $border }) => {
    switch ($color) {
      case "info":
        return `
          background: ${colorsV2.systemInfoLight};
          color: ${colorsV2.systemInfoDark};
          border: ${$border ? `1px solid ${colorsV2.systemInfoDark}` : `1px solid ${colorsV2.systemInfoLight}`};
        `
      case "error":
        return `
          background: ${colorsV2.systemErrorLight};
          color: ${colorsV2.systemErrorDark};
          border: ${$border ? `1px solid ${colorsV2.systemErrorDark}` : `1px solid ${colorsV2.systemErrorLight}`};
      `
      case "neutral":
        return `
          background: ${colorsV2.neutralDark};
          color: ${colorsV2.textSecondary};
          border: ${$border ? `1px solid ${colorsV2.textSecondary}` : `1px solid ${colorsV2.neutralDark}`};
      `
      case "good":
        return `
          background: ${colorsV2.systemGoodLight};
          color: ${colorsV2.systemGoodDark};
          border: ${$border ? `1px solid ${colorsV2.systemGoodDark}` : `1px solid ${colorsV2.systemGoodLight}`};
      `
      case "warning":
        return `
          background: ${colorsV2.systemWarningLight};
          color: ${colorsV2.systemWarningDark};
          border: ${$border ? `1px solid ${colorsV2.systemWarningDark}` : `1px solid ${colorsV2.systemWarningLight}`};
      `
      case "promotional":
        return `
          background: ${colorScale.hejmo[700]};
          color: ${colorsV2.textContrast};
          border: ${$border ? `1px solid ${colorsV2.textContrast}` : `1px solid ${colorScale.hejmo[700]}`};
      `
    }
  }}

${({ $borderRadius }) => {
    switch ($borderRadius) {
      case "round":
        return `
          border-radius: 50px;
        `
      case "square":
        return `
          border-radius: 5px;
      `
    }
  }}
`
